import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactJson from "react-json-view";
import { DEFAULT_ZOOM_FOR_FOCUSING_VIEWPORT, setPositionInCanvasView } from "../Canvas/AIBuilder/util/TranslateUtil";

import { format } from "../../../utils/Date";
import styles from "../ProjectLog/LogStyling.js";
import { send_request } from "../../../utils/Request";
import ProjectStore from "../../ProjectCanvas/ProjectStore";

// MUI
import { withStyles, styled } from "@mui/styles";
import { Grid, Button, Typography, CircularProgress, Select, MenuItem, Skeleton } from "@mui/material";
import { Timeline, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from "@mui/lab";

// MUI Icons
import { CheckCircle, RemoveCircleRounded, PauseCircleOutlineRounded, CachedRounded, CancelPresentation, Close, Pending, ErrorRounded as CrossCircle, ReplayRounded, Error, Info } from "@mui/icons-material";
import config from "../../../config";
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { capitalizeLetter, getComponentDisplayName } from "../ProjectLog/LogHelpers";
import ComponentWasReRunBox from "../ProjectLog/ComponentWasReRunBox";
import { ComponentRegistry } from "../../ProjectCanvas/ComponentRegistry";
import Paper from "@mui/material/Paper";
import Drawer from "@mui/material/Drawer";
import { Tooltip } from "@mui/material";
import { first } from "lodash";
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import './WorkflowProgressView.css';

const StyledTimelineItem = styled(TimelineItem)({
    "&::before": {
        content: "none !important",
    },
    wordBreak: "break-word",
    marginBottom: "8px",
});

const ActionButton = styled(Button)(({ bgColor, hoverBgColor, blackText }) => ({
    backgroundColor: bgColor,
    color: blackText ? "#000" : "#FFF",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    textTransform: "none",
    padding: "8px 12px",
    "&:hover": {
        backgroundColor: hoverBgColor,
    },
    "&:disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        color: "#FAFAFA",
    },
}));

const Cancel = styled(CancelPresentation)({
    color: "#FFF",
    height: "auto",
    width: "16px",
    marginRight: "8px",
});

const Loader = styled(CircularProgress)({
    position: "absolute",
    color: "rgba(33, 150, 243, 0.55)",
});

const SelectThreadBox = styled(Grid)({
    border: "1px solid rgba(186, 101, 226, 1)",
    backgroundColor: "rgba(236, 215, 246, 1)",
    padding: "8px",
    borderRadius: "8px",
});

const ThreadTopText = styled("span")({
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.1px",
    color: "rgba(0, 0, 0, 0.87)",
});

const ThreadTopDescription = styled("span")({
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "16px",
    letterSpacing: "0.1px",
    color: "rgba(117, 117, 117, 1)",
});

const StyledSelect = styled(Select)({
    maxWidth: "200px",
    zIndex: "2000",
});

function SessionInfoModal({ modalData, setModalData, classes, sessionId, getWorkflowProgressData, isTest, projectTestId, 
                            sessionData, setSessionData, handleAlterThreadPath, isLatestRun,
                            reRuns, setIsLatestRun, setLoadReRun, loadReRun, isShowRerunMenu, setShowRerunMenu, projectId, nodes, reactFlowInstance, isSplitView }) {

    const [terminateLoading, setTerminateLoading] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const isOpen = modalData.componentId != null;

    useEffect(() => {
        if (!modalData.componentId || !reactFlowInstance) return;
    
        // Grab the component from the nodes
        const component = nodes.find((ele) => ele.type === "sessionComponent" && ele.id === modalData.componentId);
        if (!component) return;

        let zoom = null;
        let rfiObj = reactFlowInstance.toObject();
        if (rfiObj.viewport && rfiObj.viewport.zoom) zoom = rfiObj.viewport.zoom;
    
        // Create new viewport object
        const newViewport = {
          ...component.position,
          zoom: zoom ? zoom : DEFAULT_ZOOM_FOR_FOCUSING_VIEWPORT
        }
    
        setPositionInCanvasView(newViewport, null, reactFlowInstance, true, projectId, null);
    }, [modalData.componentId])

    const renderIcon = (status, size) => {
        switch (status) {
            case "FAIL":
                return <CrossCircle fontSize={size} className={isLatestRun ? "cross-icon" : "grey-icon"} />;
            case "PAUSED":
                return <PauseCircleOutlineRounded fontSize={size} color={isLatestRun && "info"} className={!isLatestRun && "grey-icon"} />;
            case "SUCCESS":
            case "SUCCESS_END":
                return <CheckCircle fontSize={size} className={isLatestRun ? "check-icon" : "grey-icon"} />;
            case "INPROGRESS":
                return <CachedRounded fontSize={size} className={isLatestRun ? "default-small-icon" : "grey-icon"} />;
            case "WAITING":
                return <Pending fontSize={size} className={isLatestRun ? "small-yellow-icon" : "grey-icon"} />;
            case "TERMINATED":
                return <RemoveCircleRounded fontSize={size} className={isLatestRun ? "small-red-icon" : "grey-icon"} />;
        }
    };

    const renderStatus = (status, noStyling) => {
        let color;

        if (!noStyling) {
            switch (status) {
                case "FAIL":
                    color = "#B00020";
                    break;
                case "PAUSED":
                    color = "info";
                    break;
                case "SUCCESS":
                case "SUCCESS_END":
                    color = "#31a633";
                    break;
                case "INPROGRESS":
                    color = "black";
                    break;
                case "WAITING":
                    color = "#FF9D0B";
                    break;
                case "TERMINATED":
                    color = "#B00020";
                    break;
            }
        }

        if (status === "FAIL") {
            status = "Error";
        }

        if (status === "INPROGRESS") {
            status = "In Progress";
        }

        if (!isLatestRun) color = "rgba(218, 218, 218, 1)";

        return <span style={noStyling ? { marginLeft: "1rem" } : { color, fontWeight: "bold" }}>{capitalizeLetter(status.toLowerCase())}</span>;
    };

    const terminateAssignTask = (projectSessionId, component) => {
        let componentId = component.componentId;
        let thread = component.thread;

        if (!projectSessionId) return;
        setTerminateLoading(true);

        let reqBody = {
            isTest: isTest,
        };

        if (thread != null) reqBody["threadid"] = thread.threadId;

        let url = `project-service/project/terminate_task/${projectSessionId}/${componentId}`;

        send_request(url, "", reqBody, "post")
            .then((res) => {
                getWorkflowProgressData();
            })
            .then(() => {
                setTerminateLoading(false);
                setModalData({});
            })
            .catch((err) => {
                console.log(err);
                setTerminateLoading(false);
            });
    };

    const retryComponentSession = (projectSessionId, component) => {
        let componentId = component.componentId;
        let thread = component.thread;

        let url = "project-service/project-session/retry_component_session/" + projectSessionId + "/" + componentId;

        if (thread && thread.threadId && thread.threadId != "") {
            //thread involved
            url = url + "/" + thread.threadId;
        }

        setTerminateLoading(true);

        send_request(url, "", null, "post")
            .then((res) => {
                getWorkflowProgressData();
            })
            .then(() => {
                setTerminateLoading(false);
                setModalData({});
            })
            .catch((err) => {
                console.log(err);
                setTerminateLoading(false);
            });
    };


    const rerunComponentSession = (projectSessionId, component) => {
        let componentId = component.componentId;
        let thread = component.thread;

        let url = "project-service/project-session/rerun_component_session/" + projectSessionId + "/" + componentId;

        if (thread && thread.threadId && thread.threadId != "") {
            //thread involved
            url = url + "/" + thread.threadId;
        }

        setTerminateLoading(true);        

        send_request(url, "", null, "post")
            .then((res) => {
                getWorkflowProgressData();
            })
            .then(() => {
                setTerminateLoading(false);
                setModalData({});
            })
            .catch((err) => {
                console.log(err);
                setTerminateLoading(false);
            });
        
        setShowRerunMenu(false);
    };

    function isInputString(jsonInput) {
        if (typeof jsonInput === 'string'){
            return false;
        } 
        return true;
    }


    const getTimelineContent = (component, classes) => {
        let formSessionId = component.data && component.data.data ? component.data.formSessionId || component.data.data.formSessionId : "";
        const jsonTheme = {
                            base00: "rgba(1, 1, 1, 0)",
                            base01: "rgba(1, 1, 1, 0.1)",
                            base02: "rgba(0, 0, 0, 0.2)",
                            base03: "rgba(1, 1, 1, 0.3)",
                            base04: "rgba(0, 0, 0, 0.4)",
                            base05: "rgba(1, 1, 1, 0.5)",
                            base06: "rgba(1, 1, 1, 0.6)",
                            base07: "rgba(1, 1, 1, 0.7)",
                            base08: "rgba(0, 0, 0, 1)",
                            base09: "rgba(0, 0, 0, 1)",
                            base0A: "rgba(0, 0, 0, 1)",
                            base0B: "rgba(0, 0, 0, 1)",
                            base0C: "rgba(0, 0, 0, 1)",
                            base0D: "rgba(0, 0, 0, 1)",
                            base0E: "rgba(1, 1, 1, 0.7)",
                            base0F: "rgba(1, 1, 1, 0.7)",
        }
        
        const noInputData = (componentData) => {
            const noInput = { ...componentData };
            delete noInput['.inputData'];
            return noInput;
        }
        return (
            <Grid className="timeline-container" container>
                <Grid xs={12} className="timeline-item">
                    {getComponentDisplayName(component.type)}
                </Grid>
                <Grid container className="border-theme">
                    {(component.type === "form" || component.type === "sequential_form" || component.type === "form_section") && component.data && component.data.data ? (
                        <>
                            <Grid item xs={12}>
                                <Grid container className="grid-text-spacing">
                                        <Grid item>
                                            Form:
                                        </Grid>
                                        <Grid item xs>
                                            {!isTest ? (
                                                <a className={classes.link} href={config.FORM.FORM_FRONT_END+"form/"+component.componentId+"/"+formSessionId} target="_blank">
                                                    https://form.workflow86.com/form/
                                                    {component.componentId}/{formSessionId}
                                                </a>
                                            ) : (
                                                <a className={classes.link} href={config.FORM.FORM_FRONT_END+"form/form_test/"+component.componentId+"/" + (component.type !== "form" ? projectTestId + "/" : "") + formSessionId} target="_blank">
                                                    https://form.workflow86.com/form/form_test/{component.componentId}/{component.type !== "form" ? projectTestId + "/" : ""}{formSessionId}
                                                </a>
                                            )}
                                        </Grid>

                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        ""
                    )}
                    {component.type === "SUCCESS" && component.type === "document_editor" && component.data ? (
                        <Grid item xs={12}>
                            <Grid container className="grid-text-spacing">
                                <Grid item>
                                    Document
                                </Grid>
                                <Grid item xs wrap={"nowrap"}>
                                    <a className={classes.link} href={component.data[Object.keys(component.data)[0]]} target="_blank">
                                        {component.data[Object.keys(component.data)[0]]}
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        ""
                    )}
                    <Grid item xs={12}>
                        <Grid container className="grid-text-spacing">
                            <Grid item>Started:</Grid>
                            <Grid item xs>{format(component.startAt, ProjectStore.state.timezone)}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className="grid-text-spacing">
                            <Grid item>Ended:</Grid>
                            <Grid item xs>{format(component.endAt, ProjectStore.state.timezone)}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className="grid-text-spacing">
                            <Grid item>Status:</Grid>
                            <Grid item xs>{renderStatus(component.status)}</Grid>
                        </Grid>
                    </Grid>
                    
                    {component.error && (
                        <Grid item xs={12}>
                            <Grid container className="grid-text-spacing">
                                <Grid item>Error:</Grid>
                                <Grid item xs>
                                    <span className={"warning"}>{component.error}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>


                <Grid container marginTop={2}>
                    
                    {/*Check if the component is a form*/}
                    {(component.type === "form" || component.type === "sequential_form" || component.type === "form_section") ? (
                        /*Check if the form component is successful*/
                        component.status === "SUCCESS" ? (
                            <Grid xs={12}>
                                <Grid container className="border-theme">
                                <Grid xs={12} className="timeline-item">
                                    Data
                                </Grid>
                                    <ReactJson
                                        src={ 
                                            (component.data && component.data.data)
                                            ? noInputData(component.data.data)
                                            : component.data 
                                            ? noInputData(component.data) : {}}
                                        name="Data"
                                        iconStyle="square"
                                        displayDataTypes={false}
                                        className={classes.reactJson}
                                        theme={jsonTheme}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            /* When it is not a successful form component */
                            <Grid xs={12} className="border-theme">
                                <Grid xs={12} className="timeline-item">
                                    Form Config
                                </Grid>
                                <ReactJson
                                    src={
                                        component.data && component.data.data 
                                        ? noInputData(component.data.data) 
                                        : component.data 
                                        ? noInputData(component.data) : {}}
                                    name="Form Config"
                                    iconStyle="square"
                                    displayDataTypes={false}
                                    className={classes.reactJson}
                                    theme={jsonTheme}
                                />
                            </Grid>
                        )
                        
                    ) : (
                        /* Check if the component is Success or Success_End. Success_End is for do not continue loop condition edge case */
                        (component.status === "SUCCESS" || component.status === "SUCCESS_END") ? (
                            <Grid xs={12}>
                                <Grid container className="border-theme">
                                    <Grid xs={12} className="timeline-item">
                                        Inputs
                                    </Grid>
                                    <ReactJson 
                                        src={component.data && component.data.data 
                                            ? (isInputString(component.data.data[".inputData"]) ?  component.data.data[".inputData"] : {"Input" : component.data.data[".inputData"]})
                                            : component.data 
                                            ? (isInputString(component.data[".inputData"]) ? component.data[".inputData"] : {"Input" : component.data[".inputData"]}) 
                                            : {}}
                                        name="Input Data"
                                        iconStyle="square"
                                        displayDataTypes={false}
                                        className={classes.reactJson}
                                        theme={jsonTheme}
                                    />
                                </Grid>
                                <Grid container className="border-theme" marginTop={2}>
                                    <Grid xs={12} className="timeline-item">
                                        Outputs
                                    </Grid>
                                    <ReactJson 
                                        src={
                                            component.data && component.data.data 
                                            ? noInputData(component.data.data)
                                            : component.data 
                                            ? noInputData(component.data)
                                            : {}
                                        }
                                        name="Output Data"
                                        iconStyle="square"
                                        displayDataTypes={false}
                                        className={classes.reactJson}
                                        theme={jsonTheme}
                                    />
                                </Grid>

                            </Grid>        
                        ) : (
                            /* This is for every other component status for components that are not forms */
                            <Grid xs={12} >
                                <Grid container className="border-theme">
                                    <Grid xs={12} className="timeline-item">
                                        Inputs
                                    </Grid>
                                    <ReactJson
                                        src={component.data && component.data.data ? component.data.data : component.data ? component.data : {}}
                                        name="Input Data"
                                        iconStyle="square"
                                        displayDataTypes={false}
                                        className={classes.reactJson}
                                        theme={jsonTheme}
                                    />
                                </Grid>
                                <Grid container className="border-theme" marginTop={2}>
                                    <Grid xs={12} className="timeline-item">
                                        Outputs
                                    </Grid>
                                    <Grid xs={12} color="#868686" fontSize="14px">
                                        No outputs to display
                                    </Grid>
                                </Grid>


                            </Grid>

                        )

                    )}

                    
                </Grid>
                {component.wasReRun && 
                    <Grid item xs={12}>
                        <ComponentWasReRunBox
                            session={sessionData} 
                            reRuns={reRuns} 
                            setSession={setSessionData}
                            setIsLatestRun={setIsLatestRun}
                            setLoadReRun={setLoadReRun}
                            calculateTimeElapsed={(item) => { return item;}}
                        />
                    </Grid>
                }
            </Grid>
        );
    };

    const getBase = (component, classes) => {

        let showRerunButton = true;

        let a = ComponentRegistry.filter(
            (comp) => comp.name == component.type
          )


        if(a[0].isTrigger || component.type == "loop_through_list") {
            showRerunButton = false;
        }


        return (
            <>
                <Grid container justifyContent="space-between" alignItems="center" marginBottom={"8px"}>
                    <Typography>{format(component.startAt, ProjectStore.state.timezone, "timeline")}</Typography>
                    <Grid item>
                        <Button
                            sx={{
                                textTransform: "unset !important",
                                color: "black",
                                marginRight: "8px",
                            }}
                            size={"small"}
                            startIcon={isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            {isExpanded ? "Collapse" : "Expand"}
                        </Button>
                        <Button
                            sx={{
                                textTransform: "unset !important",
                                color: "black"
                            }}
                            size={"small"}
                            startIcon={<Close/>}
                            onClick={() => {
                                setModalData({});
                                setShowRerunMenu(false);
                                setIsExpanded(false);
                            }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
                
                <Grid container gap={1} paddingBottom="8px">
                    {showRerunButton && !isTest && component.status != "FAIL" && component.status != "INPROGRESS" && (
                        <Tooltip title="Re-run this component">
                            <ActionButton
                                className="action-button action-button-rerun"
                                startIcon={<ReplayRounded />}
                                onClick={() => rerunComponentSession(sessionId, component)} 
                                disabled={terminateLoading || !isLatestRun}
                            >
                                Re-run
                                {terminateLoading && <Loader size={24} />}
                            </ActionButton>
                        </Tooltip>
                    )}

                    {component.status === "WAITING" && (
                        <Tooltip title="Terminate this component">
                            <ActionButton 
                                className="action-button action-button-terminate"
                                startIcon={<Cancel />}
                                onClick={() => terminateAssignTask(sessionId, component)} 
                                disabled={terminateLoading || !isLatestRun} 
                                size="small"
                            >
                                Terminate
                                {terminateLoading && <Loader size={24} />}
                            </ActionButton>
                        </Tooltip>
                    )}
                    
                    {!isTest && component.status === "FAIL" && (
                        <Tooltip title="Retry this component">
                            <ActionButton 
                                className="action-button action-button-retry"
                                startIcon={<Cancel />}
                                onClick={() => retryComponentSession(sessionId, component)} 
                                disabled={terminateLoading || !isLatestRun}
                                size="small"
                            >
                                Retry
                                {terminateLoading && <Loader size={24} />}
                            </ActionButton>
                        </Tooltip>
                    )}

                    {!isTest && (!a[0].isTrigger || a[0].name === "form") && modalData.componentId && sessionId && projectId &&
                        <Tooltip title="Migrate data from the current session and re-run on the current production version">
                            <ActionButton 
                                className="action-button action-button-rerun-latest"
                                disabled={terminateLoading || !isLatestRun}
                                onClick={() => {
                                    window.open(`/project/production/${projectId}?force_start=${modalData.componentId}&selectedSession=${sessionId}`)
                                }}
                                startIcon={<MoveDownIcon />}
                                size="small"
                            >
                                Re-run on latest version
                                {terminateLoading && <Loader size={24} />}
                            </ActionButton>
                        </Tooltip>
                    }

                    <Tooltip title="Download component data as JSON">
                        <ActionButton
                            className="action-button action-button-download"
                            startIcon={<DownloadIcon />}
                            onClick={() => downloadComponentData(component)}
                            size="small"
                            blackText
                        >
                            Download JSON
                        </ActionButton>
                    </Tooltip>
                </Grid>

                <StyledTimelineItem>
                    <TimelineSeparator>
                        {renderIcon(component.status, "large")}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>{getTimelineContent(component, classes)}</TimelineContent>
                </StyledTimelineItem>
            </>
        );
    };

    const getRerunMenu = (modalData) => {
        return (
            <Grid container rowGap={1}>

                <Grid item xs={12}>
                    <b>Re-run Component</b>
                </Grid>

                <Grid item xs={12} className="error-background"> 
                    <p><Error className="iconDiscard"/><b style={{verticalAlign:"top"}}>This action will impact all downstream components</b></p>

                    
                    Run-running this component will impact the entire workflow and all downstream components. 
                    All data from downstream components that have already been run will be saved and then archived as a historical run. 
                    A new run of this workflow session will then be started. Once you have made your changes, click confirm edits and re-run to re-run the session from this point.
                    
                </Grid>
                
                <Grid item xs={12}>
                    <ActionButton onClick={() =>{ setShowRerunMenu(false); rerunComponentSession(sessionId, modalData); } } disabled={terminateLoading || !isLatestRun}>
                        <ReplayRounded />
                        Re-run
                        {terminateLoading && <Loader size={24} />}
                    </ActionButton>
                </Grid>
            </Grid>
        );
    }

    const getThreadSelect = (data) => {
        if (!data || data.type !== "loop_through_list") return;

        let parentSEThreadId = data.thread.parentThreadId;
        let successItems = [];

        for (let sDComp of sessionData.components) {
            if (sDComp.type !== "loop_through_list" || sDComp.status !== "SUCCESS") continue;
            if (sDComp.thread.parentThreadId !== parentSEThreadId) continue;
            successItems.push(sDComp);
        }

        return (
            <Grid container padding="8px">
                <Grid item xs={12}>
                    <SelectThreadBox container rowGap={1}>
                        <Grid item xs={12}>
                            <ThreadTopText>🧵 This component has {successItems.length} threads</ThreadTopText>
                        </Grid>
                        <Grid item xs={12}>
                            <ThreadTopDescription>
                                This component has multiple threads that run from this component onwards. A thread is an instance of all subsequent components in this workflow running in parallel to each other. Use the dropdown below to switch between these different threads in the progress view.
                            </ThreadTopDescription>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledSelect
                                value={data.thread.threadId}
                                size="small"
                                className="item-select"
                                onChange={(e) => {
                                    handleAlterThreadPath(data.thread.threadId, e.target.value);
                                }}
                            >
                                {successItems.map((item, idx) => {
                                    return <MenuItem value={item.thread.threadId}>{`Thread ${idx + 1} of ${successItems.length}`}</MenuItem>;
                                })}
                            </StyledSelect>
                        </Grid>
                    </SelectThreadBox>
                </Grid>
            </Grid>
        );
    };

    const getReRunLoader = () => {
        return <Grid container padding="16px" rowGap={"16px"}>
            <Grid item xs={12}>
                <StyledSkeleton variant="rectangular" height="30px" width="400px" />
            </Grid>
            <Grid item xs={12}>
             <StyledSkeleton variant="rectangular" height="800px" />
            </Grid>
        </Grid>
    }

    const downloadComponentData = (component) => {
        const componentData = component.data && component.data.data ? component.data.data : component.data ? component.data : {};
        const dataStr = JSON.stringify(componentData, null, 2);
        const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
        const exportFileDefaultName = `component_${component.componentId}_data.json`;

        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
    }

    const fetchSessionModalContent = () => {
        // If load re-run, get the re-run loader
        if (loadReRun) return getReRunLoader();
        
        // Check if it has been ran
        if (modalData.hasRun) {

            if (!isShowRerunMenu) {
                return (
                    <>
                        {getThreadSelect(modalData)}
                        <Timeline>{getBase(modalData, classes)}</Timeline>
                    </>
                );
            } else {
                return getRerunMenu(modalData);
            }

        } else {
            // Component has not been run
            return (
                <div className="not-run-yet-container">
                    <Info className="not-run-yet-icon" />
                    <Typography variant="h6" className="not-run-yet-text">
                        This component has not been run yet
                    </Typography>
                </div>
            )
        }
    }
    
    return (
        <Drawer
            open={isOpen}
            onClose={() => {
                setModalData({});
                setShowRerunMenu(false);
                setIsExpanded(false);
            }}
            disableAutoFocus
            anchor={"right"}
            hideBackdrop={true}
            variant={"persistent"}
            PaperProps={{
                sx: {
                    width: isExpanded ? "100%" : (isSplitView ? config.SESSION_LOGS_COMPONENT_INFO_PANE_WIDTH_IN_SPLIT_VIEW : config.SESSION_LOGS_COMPONENT_INFO_PANE_WIDTH),
                    position: isSplitView ? "absolute" : "fixed",
                    borderTopRightRadius: isSplitView ? "8px" : "0px",
                    borderBottomRightRadius: isSplitView ? "8px" : "0px",
                    height: "100%",
                    transition: "width 0.3s ease-in-out",
                },
            }}
        >
            {isOpen && (
                <Grid container padding="8px" style={{ height: "100%" }}>
                    <Grid item xs={12} style={{ height: "calc(100% - 40px)" }}>
                        {fetchSessionModalContent()}
                    </Grid>
                </Grid>
            )}
        </Drawer>
    );
}

SessionInfoModal.propTypes = {
    modalData: PropTypes.object.isRequired,
    setModalData: PropTypes.func.isRequired,
    classes: PropTypes.element.isRequired,
    sessionId: PropTypes.string.isRequired,
    getWorkflowProgressData: PropTypes.func.isRequired
};

export default withStyles(styles)(SessionInfoModal);

