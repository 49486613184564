import React, { useState, useEffect, useRef } from "react";
//mui components
import { Grid } from "@mui/material";

//custom components
import Chat from "./components/Chat/Chat";

//utils
import {
  send_request_prebuilt_graphql,
  send_request_graphql_mutation,
  send_request,
} from "../../../../utils/Request";
import projectStore from "../../../ProjectCanvas/ProjectStore";
import { CHECK_BUILD } from "./AIBuilderQueries";
import PropTypes from "prop-types";

//styling
import { withStyles } from "@mui/styles";
import AIBuilderStyles from "./AIBuilderStyles";

//static messages
import {
  INITIAL_LOADING_MESSAGE_COMPONENT,
  INSERT_COMPONENT_LOADING_MESSAGE,
} from "./components/Chat/StaticMessages/Loading";
//layouting
import dagre from "dagre";
import { Error, History } from "@mui/icons-material";
import { SUCCESS_MESSAGE_COMPONENT } from "./components/Chat/StaticMessages/Success";
import ProjectStore from "../../../ProjectCanvas/ProjectStore";
import { REFUSAL_ERROR_MESSAGE } from "./components/Chat/StaticMessages/REFUSAL_ERROR_MESSAGE";
import { getChatHistoryLoader } from "./util/AIChatHistoryUtil";
import { convertEventsAndSyncState } from "./util/EventTranslation/ComponentBuilderEventTranslation";
import {
  determineComponentBuildUrlBasedOnState,
  getResponseBasedOnState,
  runChatStateInitialiser,
} from "./components/Chat/ChatStateManager";
import { handleUploadFile } from "../../../../utils/FileUploadUtil";
import { ROLES } from "../AIBuilder/components/Chat/MessageRole";
const uuidv4 = require("uuid/v4");
const styles = AIBuilderStyles;
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

let timeouts = [];

const regenerateMessage = "Regenerate with the same prompt";
const REGENRATE = "REGENERATE";
const ComponentBuilderSidebar = (props) => {
  //----------------------------------------------------------------------------------------------
  // State
  //----------------------------------------------------------------------------------------------
  const [sessionTraceId, setSessionTraceId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [userPrompt, setUserPrompt] = useState(
    props.instruction ? props.instruction : ""
  );
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newComponentData, setNewComponentData] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [completedTime, setCompletedTime] = useState(null);
  const [cancelled, setCancelled] = useState(false);
  const [reset, setReset] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);
  const [
    showComponentSidebarButtons,
    setShowComponentSidebarButtons,
  ] = useState(false);
  const [hideButtons, setHideButtons] = useState(false);
  //const [sessionId, setSessionId] = useState(null);

  const [threadId, setThreadId] = useState(null);
  const [parentThreadId, setParentThreadId] = useState(null);
  const [chatHistoryLoading, setChatHistoryLoading] = useState(false);

  // Additional message
  const [showUserPrompt, setShowUserPrompt] = useState(false);
  const [additionalMessagePrompt, setAdditionalMessagePrompt] = useState("");
  //const [sidebarState, setSidebarState] = useState("INITIAL_COMP_BUILD");
  const [syncCompleted, setSyncCompleted] = useState(false);
  //const [existingComponentData,setExistingComponentData] = useState(null);
  const [messageSent, setMessageSent] = useState(false);
  const [buildInserted, setBuildInserted] = useState(false);
  const [triggerAfterSave, setTriggerAfterSave] = useState(false);
  const [files, setFiles] = useState([]);
  const chatRef = useRef(null);
  let sessionId = props.compSessionId;
  let setSessionId = props.setCompSessionId;

  //----------------------------------------------------------------------------------------------
  // User input handling
  //----------------------------------------------------------------------------------------------

  useEffect(() => {
    if (triggerAfterSave) {
      let newSessionPrompt = additionalMessagePrompt;
      setAdditionalMessagePrompt("");
      generate(false, false, null, null, null, newSessionPrompt);
      setTriggerAfterSave(false);
    }
  }, [triggerAfterSave]);

  useEffect(() => {
    if (props.showBuildPrompt && props.instructions) {
      setInitialUserPrompt();
    }
  }, [props.showBuildPrompt]);

  const setInitialUserPrompt = () => {
    if (
      props.sidebarState === "EDIT" &&
      props.instructions &&
      props.instructions.editInstruction != null
    )
      setUserPrompt(props.instructions.editInstruction);
    else if (
      props.sidebarState === "BUILD" &&
      props.instructions &&
      props.instructions.buildInstruction
    ) {
      setUserPrompt(props.instructions.buildInstruction);
    }
  };
  // For handling loading of history session
  useEffect(async () => {
    if (!props.selectedHistorySession || !props.openSessionHistory) return;
    if (props.selectedHistorySession.isReset) return;

    if (loading) {
      props.setSelectedHistorySession(null);
      return;
    }

    setTimeout(() => {
      setChatHistoryLoading(true);
    }, 0);

    let { sessionId, threadId } = props.selectedHistorySession;

    setSessionId(sessionId);
    setThreadId(threadId);

    let url = `project-service/project-ai/assistant/retrieve_ordered_events_by_sessionId_and_threadId/${sessionId}/${threadId}`;
    const json = await send_request(
      url,
      null,
      { aiEventSource: "COMPONENT_BUILDER" },
      "GET"
    );

    if (json && json.data) {
      let fns = {
        events: json.data,
        setPaneSkeleton: props.setPaneSkeleton,
        setUserPrompt,
        setMessages,
        setNewComponentData,
        setAiComponentBuilderData: props.setAiComponentBuilderData,
        setCancelled,
        setReset,
        setShowInstruction,
        setShowComponentSidebarButtons,
        setHideButtons,
        setShowUserPrompt,
        setAdditionalMessagePrompt,
        setSessionTraceId,
        setIsInAIDraft: props.setIsInAIDraft,
        setShowAnimationAsFalse,
        setSidebarState: props.setSidebarState,
        setMessageSent,
        setBuildInserted,
      };

      await convertEventsAndSyncState(fns);
    }

    // Need the set timeout here to prevent react batching the set states
    setTimeout(() => {
      setChatHistoryLoading(false);
    }, 0);
  }, [props.selectedHistorySession]);
  // ============================================= //

  //handle closing sidebar
  const handleClose = async () => {
    // Only discard if there is a draft to discard
    if (messages.length > 1 && newComponentData) {
      props.setPaneSkeleton(true);
      await discardDraftComponent();
      props.setPaneSkeleton(false);
    }
    props.setShowBuildPrompt(false);

    props.close();
    props.setSelectedHistorySession(null);
    props.setSidebarState("INITIAL_COMP_BUILD");
  };

  const updateCanvasComponentInstruction = (componentInstruction) => {
    try {
      let nodes = props.nodes;

      let currId = props.componentId;

      let ele = nodes.find((ele) => ele.id === currId);
      if (!ele) return;

      ele.data.description = componentInstruction;
      if (ele.data.instructions && typeof ele.data.instructions === "object") {
        if (props.sidebarState === "EDIT") {
          ele.data.instructions.editInstruction = "";
        } else {
          ele.data.instructions.buildInstruction = "";
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleSave = async () => {
    //check this isn't production
    //saving component
    if (!ProjectStore.state.production) {
      ProjectStore.SaveTrigger["executeTest"] = false;
      ProjectStore.SaveTrigger["sessionId"] = sessionId;
      ProjectStore.SaveTrigger["triggerValue"] =
        ProjectStore.SaveTrigger["triggerValue"] === 1 ? 0 : 1;
    }
  };

  //----------------------------------------------------------------------------------------------
  // Interacting with project service and ai builder, Polling
  //----------------------------------------------------------------------------------------------

  const sendAIRequestAndTriggerPollingForComponent = async (
    isRegenerated,
    isAdditionalMessage,
    existingComponentData,
    givenSessionId,
    givenThreadId,
    givenSessionTraceId,
    newSessionPrompt,
    imageFileUrl
  ) => {
    props.setShowBuildPrompt(false);
    if (isRegenerated) {
      setShowComponentSidebarButtons(false);
    }
    //start timer
    setStartTime(new Date().getTime());
    props.setIsInAIDraft(false);

    let isNewSession = true;

    let currentSessionID = null;
    let currentThreadID = null;

    let currentSessionTraceID = null;

    // If it's an additional message, use the existing session id
    if (isAdditionalMessage || isRegenerated) {
      currentSessionID = givenSessionId;
      currentThreadID = givenThreadId;

      currentSessionTraceID = givenSessionTraceId;

      isNewSession = false;
    } else {
      currentSessionID = uuidv4();
      setSessionId(currentSessionID);

      currentThreadID = uuidv4();
      setThreadId(currentThreadID);

      currentSessionTraceID = uuidv4();
      setSessionTraceId(currentSessionTraceID);
    }

    const chatUserPrompt = !newSessionPrompt
      ? !isAdditionalMessage
        ? userPrompt
        : additionalMessagePrompt
      : newSessionPrompt;

    let currentChat = createChat(chatUserPrompt);

    //disable buttons on loading
    props.setPaneSkeleton(true);
    let componentInfo = [
      {
        componentId: props.componentId,
        componentType: props.componentType,
        userPrompt: isRegenerated ? REGENRATE : chatUserPrompt,
        existingComponentResponse: JSON.stringify(existingComponentData),
      },
    ];
    let aiComponentBuilderDto = {
      projectId: props.projectId,
      sessionId: currentSessionID,
      threadId: currentThreadID,
      sessionTraceId: currentSessionTraceID,
      componentInfos: componentInfo,
      saveComponentData: false,
      retryComponent: false,
      isAdditionalMessage: isAdditionalMessage,
      newSessionPrompt: isRegenerated ? REGENRATE : newSessionPrompt,
      imageFileUrl: imageFileUrl,
    };
  
    

    // send off user input and trigger system+user prompt
    await send_request_graphql_mutation(
      `project-service/graphql/project/${props.projectId}`,
      determineComponentBuildUrlBasedOnState(
        aiComponentBuilderDto,
        props.sidebarState
      )
    )
      .then(async (response) => {
        if (
          getResponseBasedOnState(response, props.sidebarState) === "SUCCESS"
        ) {
          let timeout = setTimeout(() => {
            checkBuild(
              currentSessionID,
              currentThreadID,
              currentSessionTraceID,
              0,
              isRegenerated,
              currentChat
            );
          }, 5000);
          timeouts.push(timeout);
        } else {
          //error
          showError(
            currentChat,
            getResponseBasedOnState(response, props.sidebarState)
          );
        }
      })
      .catch(async (e) => {
        console.log(e);
        showError(currentChat, "Error while building component"); // need to handle
      });
  };

  //submit user input to the ai builder service
  const generate = async (
    isRegenerated = false,
    isAdditionalMessage = false,
    givenSessionId,
    givenThreadId,
    givenSessionTraceId,
    newSessionPrompt,
    imageFileUrl
  ) => {
    if (buildInserted && isAdditionalMessage) {
      // change to new edit session
      restart(true);
      return;
    }
    setIsLoading(true);
    let imageUrl = await handleUploadFile(files);
    setIsLoading(false);

    setMessageSent(true);
    let arr = messages;
    let uploadedFiles = files;
    arr.push({ role: ROLES.IMAGE, content: uploadedFiles });
    URL.revokeObjectURL(files[0]);

    setFiles([]);
    if (userPrompt || additionalMessagePrompt || newSessionPrompt) {
      arr.push({
        role: ROLES.USER,
        content: !newSessionPrompt
          ? !isAdditionalMessage
            ? userPrompt
            : additionalMessagePrompt
          : newSessionPrompt,
      });
    }
    setMessages((prevMessages) => [...arr]);
    setLoading(true);
    if (props.sidebarState === "EDIT") {
      if (!isAdditionalMessage && !isRegenerated) {
        if (props.handleSave) props.handleSave();
        else handleSave();
      }
      let arr = messages;
      arr.push({
        role: "syncState",
        content: "",
      });
      setMessages((prevMessages) => [...arr]);

      let timeout = setTimeout(() => {
        {
          let requestBody = {
            isUpdate: true,
            userMarkedSkip: false,
          };

          let url = `project-service/project-ai/assistant/getExistingComponentData/${props.componentId}`;
          send_request(url, requestBody, "", "POST")
            .catch((err) => {
              throw Error(err.message);
            })
            .then((res) => {
              if (res && res.data) {
                setSyncCompleted(true);

                if (res.data.editable) {
                  sendAIRequestAndTriggerPollingForComponent(
                    isRegenerated,
                    isAdditionalMessage,
                    res.data,
                    givenSessionId,
                    givenThreadId,
                    givenSessionTraceId,
                    newSessionPrompt,
                    imageUrl
                  );
                } else {
                  showError(arr, "Component is not editable");
                }
              }
            });
        }
      }, 3000);
      timeouts.push(timeout);
    } else {
      await sendAIRequestAndTriggerPollingForComponent(
        isRegenerated,
        isAdditionalMessage,
        null,
        givenSessionId,
        givenThreadId,
        givenSessionTraceId,
        newSessionPrompt,
        imageUrl
      );
    }
  };

  //function that does polling for result of the ai builder
  const checkBuild = async (
    sessionId,
    threadId,
    sessionTraceId,
    polled,
    isRegenerated,
    currentChat
  ) => {
    if (cancelled) return;

    // setLoading(false);

    const pollMax = 20;

    if (polled <= pollMax && !cancelled) {
      polled = polled + 1;

      try {
        const response = await send_request_prebuilt_graphql(
          `project-service/graphql/project/${props.projectId}`,
          CHECK_BUILD(sessionId, true, polled, pollMax)
        );

        if (response && response.data.checkBuild) {
          let url = `project-service/project/retrieve_ai_component/${sessionId}/${props.componentId}/${props.componentType}`;
          url += props.sidebarState === "EDIT" ? "/true" : "/false";

          const res = await send_request(url, "", { threadId: threadId }).catch(
            (err) => {
              throw Error(err.message);
            }
          );
          if (res && res.data && !res.data.errorInfo && !res.data.aiAnswer) {
            await parseComponentAndDisplayDraft(
              res.data,
              isRegenerated,
              currentChat,
              sessionId,
              threadId,
              sessionTraceId
            );
            props.setPaneSkeleton(false);

            setLoading(false);
          } else if (res && res.data && res.data.aiAnswer) {
            showExplanation(currentChat, res.data.aiAnswer);
          } else {
            let type = "";
            let errorInfo = "";

            if (res.response.data.code === "GPT_REFUSE_EXCEPTION") {
              type = "GPT_REFUSE_EXCEPTION";
              errorInfo = projectStore.state.userName;
              showError(currentChat, REFUSAL_ERROR_MESSAGE(errorInfo).content);
            } else {
              type = "GENERIC_ERROR";
              errorInfo = res.response.data.message;
              showError(currentChat, errorInfo);
            }
          }
        } else if (!response.data.checkBuild) {
          let timeout = setTimeout(() => {
            checkBuild(
              sessionId,
              threadId,
              sessionTraceId,
              polled,
              isRegenerated,
              currentChat
            );
          }, 5000);
          timeouts.push(timeout);
        }
      } catch (err) {
        console.log(err);
        let timeout = setTimeout(() => {
          checkBuild(
            sessionId,
            threadId,
            sessionTraceId,
            polled,
            isRegenerated,
            currentChat
          );
        }, 5000);
        timeouts.push(timeout);
      }
    } else {
      showError(currentChat, "");
      setLoading(false);
    }
  };

  //----------------------------------------------------------------------------------------------
  // Reading diagram
  //----------------------------------------------------------------------------------------------

  //this function takes care of creating ai builder draft and adding it to the canvas
  const parseComponentAndDisplayDraft = async (
    component,
    isRegenerated,
    currentChat,
    currentSessionID,
    currentThreadID,
    currentSessionTraceID
  ) => {
    setNewComponentData(component);
    props.setAiComponentBuilderData(component);
    props.setIsInAIDraft(true);

    setLoading(false);

    let explanation = component["explanation"];
    if (!props.setComponentInstruction) {
      updateCanvasComponentInstruction(explanation);
    } else {
      props.setComponentInstruction(explanation);
    }
    // Update chat messages
    const latestChat = updateChatMessages(
      currentChat,
      explanation,
      currentSessionID,
      currentThreadID,
      currentSessionTraceID
    );
    setMessages((prevMessages) => [...latestChat]);

    // Read diagram
    setShowInstruction(true);

    setShowUserPrompt(true);
    setShowComponentSidebarButtons(true);
    setAdditionalMessagePrompt("");

    // Count how long it took the request to finish
    const finishedTime = new Date().getTime();
    setCompletedTime(finishedTime);
  };

  const setShowAnimationAsFalse = (currentChat) => {
    return currentChat.map((message) => {
      if (message.role === "loading") {
        return {
          ...message,
          showAnimation: false,
        };
      }
      return message;
    });
  };

  const updateChatMessages = (
    currentChat,
    explanation,
    currentSessionID,
    currentThreadID,
    currentSessionTraceID
  ) => {
    currentChat = setShowAnimationAsFalse(currentChat);

    let updatedMessages = [...currentChat];

    if (explanation) {
      updatedMessages.push({
        content: explanation,
        role: "assistant",
        showTimer: true,
      });
    }

    updatedMessages.push({ role: "component_instruction", content: "" });
    return updatedMessages;
  };

  //----------------------------------------------------------------------------------------------
  // Handling User Interactions in the Chat
  //----------------------------------------------------------------------------------------------

  //restart ai builder interaction
  const restart = async (afterSessionEnd = false) => {
    props.setPaneSkeleton(true);

    //get rid of the current sidebar state
    await discardDraftComponent(afterSessionEnd);
    props.setPaneSkeleton(false);

    //

    // setCancelled((prevCancelled) => true);
    setMessageSent(false);

    setCancelled(false);
    setSessionId(null);
    setThreadId(null);
    setUserPrompt("");
    // setAdditionalMessagePrompt("");
    setNewComponentData("");
    setShowInstruction(false);
    setShowComponentSidebarButtons(false);
    setShowUserPrompt(false);
    props.setIsInAIDraft(false);
    if (afterSessionEnd) {
      props.setSidebarState("EDIT");
      setBuildInserted(false);

      setMessages([]);

      runChatStateInitialiser(
        "EDIT",
        props.setSidebarState,
        chatHistoryLoading,
        setMessages,
        props,
        null
      );
      setTriggerAfterSave(true);
      setMessages([]);
    } else {
     props.setState("INITIAL");
    }
  };

  //insert a new workflow to the canvas
  const insert = async () => {
    let arr = messages;

    const messageContent = "Configure and save component";

    // Insert loading meseages
    arr.push({
      role: ROLES.USER,
      showTimer: false,
      content: messageContent,
    });

    arr.push(INSERT_COMPONENT_LOADING_MESSAGE);
    setMessages((prevMessages) => [...arr]);

    setShowComponentSidebarButtons(false);
    setHideButtons(true);
    setLoading(true);
    //setShowUserPrompt(false);
    props.setIsInAIDraft(false);

    if (props.handleSave) props.handleSave(sessionId);
    else handleSave();

    props.setPaneSkeleton(true);

    //turn off saving state
    let timeout = setTimeout(async () => {
      // After saving to the component, we display the success message
      arr.push(SUCCESS_MESSAGE_COMPONENT);
      arr = setShowAnimationAsFalse(arr);
      setMessages((prevMessages) => [...arr]);

      props.setPaneSkeleton(false);
      setShowComponentSidebarButtons(true);
      setHideButtons(false);
      setNewComponentData("");
      setBuildInserted(true);
      setLoading(false);
    }, 3000);
    timeouts.push(timeout);
  };

  //regenerate ai builder response by resubmitting
  const regenerate = async () => {
    // Set the isRegenerating state
    setReset(true);

    // Reset the cancelled state
    setCancelled(false);

    // Discard the current diagram
    props.setPaneSkeleton(true);

    //get rid of the current sidebar state
    await discardDraftComponent();

    // Regenerate the response and update the chat messages
    generate(
      true,
      false,
      sessionId,
      threadId,
      sessionTraceId,
      regenerateMessage
    );

    setNewComponentData(""); // Reset the new components

    setShowInstruction(true);
  };

  const editAndRegenerate = async () => {
    // Set skeleton, discard component and turn off skeleton
    props.setPaneSkeleton(true);
    await discardDraftComponent();
    props.setPaneSkeleton(false);

    // Go back to initial message
    setMessageSent(false);
    props.setSidebarState("INITIAL_COMP_BUILD");
    const message = {
      role: "initialComponentMessage",
      content: "",
      setSidebarState: props.setSidebarState,
    };

    setMessages([message]);
    setCancelled(false);
    setSessionId(null);
    setThreadId(null);
    setSessionTraceId(null);
    props.setIsInAIDraft(false);

    setNewComponentData("");
    setShowInstruction(false);
    setShowComponentSidebarButtons(false);
  };

  //cancel current request
  const cancel = async () => {
    setCancelled(true);

    // Remove any timeouts for api requests
    for (let i = 0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
    timeouts = [];
    let arr = messages;
    arr = setShowAnimationAsFalse(arr);

    arr.push({ role: ROLES.USER, content: "Cancel request" });
    arr.push({ role: "assistant", content: "❌ Request cancelled" });

    setMessages((prevMessages) => [...arr]);
    setUserPrompt("");
    setAdditionalMessagePrompt("");
    setShowComponentSidebarButtons(true);

    // await restart();
    setLoading(false);
    props.setPaneSkeleton(false);
  };

  //----------------------------------------------------------------------------------------------
  // Utils for Chat Interaction
  //----------------------------------------------------------------------------------------------

  //Handlse discard draft component
  const discardDraftComponent = async (afterSessionEnd = false) => {
    // need to do this to ensure reset actually resets the state, because there is
    // a null check that avoid updation of ai builder data
    if (afterSessionEnd)
      props.setAiComponentBuilderData({ afterSessionEnd: true });
    else props.setAiComponentBuilderData({});
    props.setIsInAIDraft(false);
  };

  //create a new chat when user has put their prompt in
  const createChat = (userPrompt) => {
    let arr = messages;

    arr.push(INITIAL_LOADING_MESSAGE_COMPONENT);

    setMessages((prevMessages) => [...arr]);

    return arr;
  };

  const showExplanation = async (currentChat, content) => {
    currentChat = currentChat.filter((message) => {
      // Filter out messages with role "loading"
      return message.role !== "loading";
    });

    //add error message to the messages array
    let arr = currentChat;
    if (arr) {
      arr.push({ role: "success", content: content });

      // Set state for error
      setLoading(false);
      props.setPaneSkeleton(true);

      setNewComponentData("");
      setShowInstruction(false);
      setShowUserPrompt(true);
      setAdditionalMessagePrompt("");
      setShowComponentSidebarButtons(true);
      props.setPaneSkeleton(false);
      setMessages((prevMessages) => [...arr]);
    }
  };

  //add an error to the chat
  const showError = async (currentChat, content) => {
    currentChat = currentChat.map((message) => {
      if (message.role === "loading") {
        return {
          ...message,
          showAnimation: false,
        };
      }
      return message;
    });
    if (
      !currentChat.some((m) => m.role === "instruction" || m.role === "success")
    ) {
      //add error message to the messages array
      let arr = currentChat;
      if (arr) {
        arr.push({ role: "error", content: content });

        // Set state for error
        setLoading(false);
        props.setPaneSkeleton(true);

        //get rid of the current sidebar state
        await discardDraftComponent();
        props.setPaneSkeleton(false);
        setNewComponentData("");
        setShowInstruction(false);
        setShowComponentSidebarButtons(true);
        props.setPaneSkeleton(false);

        setMessages((prevMessages) => [...arr]);
      }
    }
  };

  //----------------------------------------------------------------------------------------------
  // Canvas Layout
  //----------------------------------------------------------------------------------------------
  useEffect(() => {
    const message = {
      role: "initialComponentMessage",
      content: "",
      setSidebarState: props.setSidebarState,
    };

    setMessages([message]);
  }, []);

  useEffect(() => {
    if (props.triggerCloseInChild) {
      handleClose().then(props.setTriggerCloseInChild(false));
    }
  }, [props.triggerCloseInChild]);
  useEffect(() => {
    if (triggerAfterSave) return;
    runChatStateInitialiser(
      props.sidebarState,
      props.setSidebarState,
      chatHistoryLoading,
      setMessages,
      props,
      null
    );
    setInitialUserPrompt();
  }, [props.sidebarState]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  //----------------------------------------------------------------------------------------------
  // unmount
  //----------------------------------------------------------------------------------------------
  useEffect(() => {
    return () => {
      discardDraftComponent();
      props.setSelectedHistorySession(null);
      props.setIsInAIDraft(false);
    };
  }, []);
  //----------------------------------------------------------------------------------------------
  // JSX
  //----------------------------------------------------------------------------------------------

  //takes care of rendering interactive components inside the sidebar
  const renderSidebar = () => {
    return (
      <Grid container className={props.classes.fullChat} direction="column">
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems={messages.length > 0 ? "center" : "flex-start"}
            className={messages.length > 0 ? props.classes.chatHeader : ""}
          >
            <span>
              <b>
                {props.sidebarState === "INITIAL_COMP_BUILD"
                  ? "Start new session"
                  : "AI Component Builder"}
              </b>
            </span>
            {sessionId && (
              <span
                className={props.classes.sessionId}
                onClick={() => navigator.clipboard.writeText(sessionId)}
                style={{ cursor: 'pointer' }}
              >
                {sessionId}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid item xs>
          {chatHistoryLoading ? (
            getChatHistoryLoader()
          ) : (
            <Chat
              paneSkeleton={props.paneSkeleton}
              isInit={props.isInit}
              isCompBuilder={true}
              showAdditionalPrompt
              showUserPrompt={showUserPrompt}
              additionalMessagePrompt={additionalMessagePrompt}
              setAdditionalMessagePrompt={setAdditionalMessagePrompt}
              generateWithAdditional={() => {
                generate(
                  false,
                  true,
                  sessionId,
                  threadId,
                  sessionTraceId,
                  null
                );
              }}
              chatRef={chatRef}
              hideButtons={hideButtons}
              classes={props.classes}
              reset={reset}
              messages={messages}
              restart={() => {
                restart();
              }}
              regenerate={() => {
                regenerate();
              }}
              insert={() => {
                insert();
              }}
              loading={loading}
              startTime={startTime}
              completedTime={completedTime}
              cancel={() => {
                cancel();
              }}
              userPrompt={userPrompt}
              updateUserPrompt={(val) => {
                setUserPrompt(val);
              }}
              generate={() => {
                generate();
              }}
              editAndRegenerate={() => {
                editAndRegenerate();
              }}
              canInsert={
                props.aiComponentBuilderData &&
                Object.keys(props.aiComponentBuilderData).length === 0
              }
              files={files}
              setFiles={setFiles}
              cancelled={cancelled}
              startCompTestRun={props.startCompTestRun}
              messageSent={messageSent}
              showInstruction={showInstruction}
              setSidebarState={props.setSidebarState}
              sidebarState={props.sidebarState}
              syncCompleted={syncCompleted}
              buildInserted={buildInserted}
              showComponentSidebarButtons={showComponentSidebarButtons}
              chatHistoryLoading={chatHistoryLoading}
              isLoading={isLoading}
              buttonText={{
                insert: {
                  message: "Accept and Save",
                  tooltip: "Save the proposed component configuration",
                },
                restart: {
                  message: "Discard",
                  tooltip: "Start a new chat session",
                },
                regenerate: {
                  message: "Regenerate",
                  tooltip: "Regenerate the response using the same message",
                },
                editAndRegenerate: {
                  message: "Edit",
                  tooltip:
                    "Edit the original message and regenerate the response",
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  //drawer setup
  return (
    <Grid
      container
      className={
        !props.isExpandedPage
          ? props.classes.insidePaper
          : props.classes.fullChat
      }
      direction="column"
    >
      <Grid item>
        <Grid container padding="4px">
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          ></Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        {renderSidebar()}
      </Grid>
    </Grid>
  );
};

ComponentBuilderSidebar.propTypes = {
  open: PropTypes.bool.isRequired, //A boolean value indicating whether the Drawer is open.
  close: PropTypes.func.isRequired, // A function to close the Drawer.
  projectId: PropTypes.string.isRequired, //A string representing the project ID.
  nodes: PropTypes.array.isRequired, //An array containing the canvas nodes.
  reactFlowInstance: PropTypes.object.isRequired, //An object representing the React Flow instance
  classes: PropTypes.object.isRequired, //An object containing the CSS classes for the component.
  triggerCloseInChild: PropTypes.bool.isRequired,
  setTriggerCloseInChild: PropTypes.func.isRequired,
};
export default withStyles(styles)(ComponentBuilderSidebar);
